module.exports = [{
      plugin: require('C:/Users/desen/Documents/git/francescjimenez.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10762539-1"},
    },{
      plugin: require('C:/Users/desen/Documents/git/francescjimenez.com/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Francesc Jimenez","short_name":"Francesc Jimenez","description":"My Image-heavy photography portfolio with all my love from Barcelona.","start_url":"/","background_color":"#3b3c4f","theme_color":"#db7436","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('C:/Users/desen/Documents/git/francescjimenez.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
