// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-tsx": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\src\\templates\\project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-pages-about-tsx": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\src\\pages\\about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-tsx": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\src\\pages\\instagram.tsx" /* webpackChunkName: "component---src-pages-instagram-tsx" */),
  "component---src-pages-projects-tsx": () => import("C:\\Users\\desen\\Documents\\git\\francescjimenez.com\\src\\pages\\projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

